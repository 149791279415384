<template>
	<div>
		<div class="smtFactory">
			<div class="sf_titleDiv">
				<div class="sf_td_title">{{$t('steelMesh.smtFactory.title')}}</div>
				<div class="sf_td_title">{{$t('steelMesh.smtFactory.text1')}}— <span style="color: #2a915f;">{{$t('steelMesh.smtFactory.text2')}}</span></div>
			</div>
			
			<div class="sf_contentDiv">
				<div class="sf_cd_img">
					<img style="width: 100%;" :src="$t('steelMesh.smtFactory.img1')" />
				</div>
				<div class="sf_cd_img">
					<img style="width: 100%;" :src="$t('steelMesh.smtFactory.img2')" />
				</div>
				<div class="sf_cd_img">
					<img style="width: 100%;" :src="$t('steelMesh.smtFactory.img3')" />
				</div>
				<div class="sf_cd_img">
					<img style="width: 100%;" :src="$t('steelMesh.smtFactory.img4')" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				
			}
		},
		
		created() {
			
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.smtFactory{
			padding: 0 200px 40px 200px;
			
			.sf_titleDiv{
				text-align: center;
				
				.sf_td_title{
					font-size: 32px;
					color: #555555;
				}
			}
			
			.sf_contentDiv{
				padding-top: 20px;
				display: flex;
				width: 100%;
				
				.sf_cd_img{
					padding: 2vw;
					background: #fff;
					width: 23%;
					margin-right: 2%;
					box-shadow: 0 4px 4px #eeefef;
					border: 1px solid #e6e6e6;
				}
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.smtFactory{
			padding: 0 200px 40px 200px;
			
			.sf_titleDiv{
				text-align: center;
				
				.sf_td_title{
					font-size: 32px;
					color: #555555;
				}
			}
			
			.sf_contentDiv{
				padding-top: 20px;
				display: flex;
				width: 100%;
				
				.sf_cd_img{
					padding: 2vw;
					background: #fff;
					width: 23%;
					margin-right: 2%;
					box-shadow: 0 4px 4px #eeefef;
					border: 1px solid #e6e6e6;
				}
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.smtFactory{
			padding: 0 140px 40px 140px;
			
			.sf_titleDiv{
				text-align: center;
				
				.sf_td_title{
					font-size: 32px;
					color: #555555;
				}
			}
			
			.sf_contentDiv{
				padding-top: 20px;
				display: flex;
				width: 100%;
				
				.sf_cd_img{
					padding: 2vw;
					background: #fff;
					width: 23%;
					margin-right: 2%;
					box-shadow: 0 4px 4px #eeefef;
					border: 1px solid #e6e6e6;
				}
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.smtFactory{
			padding: 0 30px 40px 30px;
			
			.sf_titleDiv{
				text-align: center;
				
				.sf_td_title{
					font-size: 32px;
					color: #555555;
				}
			}
			
			.sf_contentDiv{
				padding-top: 20px;
				display: flex;
				width: 100%;
				
				.sf_cd_img{
					padding: 2vw;
					background: #fff;
					width: 23%;
					margin-right: 2%;
					box-shadow: 0 4px 4px #eeefef;
					border: 1px solid #e6e6e6;
				}
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.smtFactory{
			padding: 0 30px 40px 30px;
			
			.sf_titleDiv{
				text-align: center;
				
				.sf_td_title{
					font-size: 21px;
					color: #555555;
				}
			}
			
			.sf_contentDiv{
				padding-top: 20px;
				display: flex;
				justify-content: center;
				flex-wrap: wrap;
				width: 100%;
				
				.sf_cd_img{
					padding: 2vw;
					background: #fff;
					width: 48%;
					margin-bottom: 10px;
					margin-right: 2%;
					box-shadow: 0 4px 4px #eeefef;
					border: 1px solid #e6e6e6;
				}
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.smtFactory{
			padding: 0 30px 40px 30px;
			
			.sf_titleDiv{
				text-align: center;
				
				.sf_td_title{
					font-size: 21px;
					color: #555555;
				}
			}
			
			.sf_contentDiv{
				padding-top: 20px;
				display: flex;
				justify-content: center;
				flex-wrap: wrap;
				width: 100%;
				
				.sf_cd_img{
					padding: 2vw;
					background: #fff;
					width: 100%;
					margin-bottom: 10px;
					box-shadow: 0 4px 4px #eeefef;
					border: 1px solid #e6e6e6;
				}
			}
		}
	}
	
</style>