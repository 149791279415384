<template>
	<div>
		<div class="chooseUs" v-if="windowWidth>=1200">
			<div class="cu_title">{{$t('steelMesh.chooseUs.title')}}</div>
			
			<div class="cu_contentDiv">
				<div class="cu_cd_div" v-for="(item,index) in $t('steelMesh.chooseUs.list')" :key="index">
					<div class="cu_cd_di_title">{{item.title}}</div>
					<div class="cu_cd_di_text">{{item.content}}</div>
					<img class="cu_cd_di_img" :src="item.image" alt width="62" height="62" />
				</div>
			</div>
		</div>
		
		<div class="chooseUs2" v-if="windowWidth<=1199">
			<div class="cu_title">{{$t('steelMesh.chooseUs.title')}}</div>
			
			<div class="cu_contentDiv">
				<div class="cu_cd_div" v-for="(item,index) in $t('steelMesh.chooseUs.list')" :key="index">
					<div class="cu_cd_di_title">{{item.title}}</div>
					<div class="cu_cd_di_text">{{item.content}}</div>
					<img class="cu_cd_di_img" :src="item.image" alt width="62" height="62" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
			}
		},
		
		created() {
			
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.chooseUs{
			background: #fff;
			padding: 40px 200px;
			
			.cu_title{
				text-align: center;
				color: #444444;
				font-size: 32px;
				margin-top: 40px;
			}
			
			.cu_contentDiv{
				display: flex;
				width: 100%;
				flex-wrap: wrap;
				
				.cu_cd_div{
					width: 31%;
					margin-right: 2%;
					margin-top: 2%;
					height: 220px;
					background-color: #cbe8da;
					padding: 44px 0 0 34px;
					box-sizing: border-box;
					position: relative;
					color: #333333;
					
					.cu_cd_di_title{
						font-size: 18px;
						font-weight: bold;
					}
					
					.cu_cd_di_text{
						font-size: 14px;
						margin-top: 20px;
						width: 280px;
					}
					
					.cu_cd_di_img{
						position: absolute;
						right: 30px;
						bottom: 20px;
						z-index: 6;
						transition: all .3s;
					}
				}
				
				.cu_cd_div:hover .cu_cd_di_img{
					transform: scale(1.3,1.3);
				}
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.chooseUs{
			background: #fff;
			padding: 40px 200px;
			
			.cu_title{
				text-align: center;
				color: #444444;
				font-size: 32px;
				margin-top: 40px;
			}
			
			.cu_contentDiv{
				display: flex;
				width: 100%;
				flex-wrap: wrap;
				
				.cu_cd_div{
					width: 31%;
					margin-right: 2%;
					margin-top: 2%;
					height: 220px;
					background-color: #cbe8da;
					padding: 44px 0 0 34px;
					box-sizing: border-box;
					position: relative;
					color: #333333;
					
					.cu_cd_di_title{
						font-size: 18px;
						font-weight: bold;
					}
					
					.cu_cd_di_text{
						font-size: 14px;
						margin-top: 20px;
						width: 280px;
					}
					
					.cu_cd_di_img{
						position: absolute;
						right: 30px;
						bottom: 20px;
						z-index: 6;
						transition: all .3s;
					}
				}
				
				.cu_cd_div:hover .cu_cd_di_img{
					transform: scale(1.3,1.3);
				}
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.chooseUs{
			background: #fff;
			padding: 40px 140px;
			
			.cu_title{
				text-align: center;
				color: #444444;
				font-size: 32px;
				margin-top: 40px;
			}
			
			.cu_contentDiv{
				display: flex;
				width: 100%;
				flex-wrap: wrap;
				
				.cu_cd_div{
					width: 31%;
					margin-right: 2%;
					margin-top: 2%;
					height: 220px;
					background-color: #cbe8da;
					padding: 44px 0 0 34px;
					box-sizing: border-box;
					position: relative;
					color: #333333;
					
					.cu_cd_di_title{
						font-size: 18px;
						font-weight: bold;
					}
					
					.cu_cd_di_text{
						font-size: 14px;
						margin-top: 20px;
						width: 280px;
					}
					
					.cu_cd_di_img{
						position: absolute;
						right: 30px;
						bottom: 20px;
						z-index: 6;
						transition: all .3s;
					}
				}
				
				.cu_cd_div:hover .cu_cd_di_img{
					transform: scale(1.3,1.3);
				}
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.chooseUs{
			background: #fff;
			padding: 40px 30px;
			
			.cu_title{
				text-align: center;
				color: #444444;
				font-size: 32px;
				margin-top: 40px;
			}
			
			.cu_contentDiv{
				display: flex;
				width: 100%;
				flex-wrap: wrap;
				
				.cu_cd_div{
					width: 31%;
					margin-right: 2%;
					margin-top: 2%;
					height: 220px;
					background-color: #cbe8da;
					padding: 44px 0 0 34px;
					box-sizing: border-box;
					position: relative;
					color: #333333;
					
					.cu_cd_di_title{
						font-size: 18px;
						font-weight: bold;
					}
					
					.cu_cd_di_text{
						font-size: 14px;
						margin-top: 20px;
						width: 280px;
					}
					
					.cu_cd_di_img{
						position: absolute;
						right: 30px;
						bottom: 20px;
						z-index: 6;
						transition: all .3s;
					}
				}
				
				.cu_cd_div:hover .cu_cd_di_img{
					transform: scale(1.3,1.3);
				}
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.chooseUs2{
			background: #fff;
			padding: 40px 30px;
			
			.cu_title{
				text-align: center;
				color: #444444;
				font-size: 21px;
			}
			
			.cu_contentDiv{
				display: flex;
				width: 100%;
				flex-wrap: wrap;
				
				.cu_cd_div{
					width: 48%;
					margin-right: 2%;
					margin-top: 2%;
					height: 220px;
					background-color: #cbe8da;
					padding: 44px 0 0 34px;
					box-sizing: border-box;
					position: relative;
					color: #333333;
					
					.cu_cd_di_title{
						font-size: 18px;
						font-weight: bold;
					}
					
					.cu_cd_di_text{
						font-size: 14px;
						margin-top: 20px;
						width: 280px;
					}
					
					.cu_cd_di_img{
						position: absolute;
						right: 30px;
						bottom: 20px;
						z-index: 6;
						transition: all .3s;
					}
				}
				
				.cu_cd_div:hover .cu_cd_di_img{
					transform: scale(1.3,1.3);
				}
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.chooseUs2{
			background: #fff;
			padding: 40px 30px;
			
			.cu_title{
				text-align: center;
				color: #444444;
				font-size: 21px;
			}
			
			.cu_contentDiv{
				display: flex;
				width: 100%;
				flex-wrap: wrap;
				
				.cu_cd_div{
					width: 100%;
					margin-top: 2%;
					height: 220px;
					background-color: #cbe8da;
					padding: 44px 0 0 34px;
					box-sizing: border-box;
					position: relative;
					color: #333333;
					
					.cu_cd_di_title{
						font-size: 18px;
						font-weight: bold;
					}
					
					.cu_cd_di_text{
						font-size: 14px;
						margin-top: 20px;
						width: 280px;
					}
					
					.cu_cd_di_img{
						position: absolute;
						right: 30px;
						bottom: 20px;
						z-index: 6;
						transition: all .3s;
					}
				}
				
				.cu_cd_div:hover .cu_cd_di_img{
					transform: scale(1.3,1.3);
				}
			}
		}
	}
	
</style>