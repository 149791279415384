<template>
	<div>
		<div class="userInformation" :style="'background-image:' + `url(${img})`" v-if="windowWidth>=1200">
			<div class="ui_title">{{$t('steelMesh.userInformation.title')}}</div>
			<div class="ui_textDiv">
				<div class="ui_td_title">{{$t('steelMesh.userInformation.text1')}}</div>
				<div class="ui_td_text">{{$t('steelMesh.userInformation.text2')}}</div>
			</div>
			<table class="ui_table">
				<tbody class="ui_tb_tbody">
					<tr class="ui_tb_td_gaugeOutfit">
						<th style="width: 20%;"></th>
						<th style="width: 50%;"></th>
						<th style="width: 30%;"></th>
					</tr>
					<tr class="ui_tb_td_capability">
						<td class="ui_tb_td_cb_text">{{$t('steelMesh.userInformation.tableGaugeOutfit.text1')}}</td>
						<td class="ui_tb_td_cb_text">{{$t('steelMesh.userInformation.tableGaugeOutfit.text2')}}</td>
						<td class="ui_tb_td_cb_text">{{$t('steelMesh.userInformation.tableGaugeOutfit.text3')}}</td>
					</tr>
					<tr class="ui_tb_td_capability">
						<td class="ui_tb_td_cb_text" rowspan="2">CHIP</td>
						<td class="ui_tb_td_cb_text">0402</td>
						<td class="ui_tb_td_cb_text">0.12mm</td>
					</tr>
					<tr class="ui_tb_td_capability">
						<td class="ui_tb_td_cb_text">0201</td>
						<td class="ui_tb_td_cb_text">0.10mm</td>
					</tr>
					
					<tr class="ui_tb_td_capability">
						<td class="ui_tb_td_cb_text" rowspan="4">QFP</td>
						<td class="ui_tb_td_cb_text">0.65</td>
						<td class="ui_tb_td_cb_text">0.15mm</td>
					</tr>
					<tr class="ui_tb_td_capability">
						<td class="ui_tb_td_cb_text">0.50</td>
						<td class="ui_tb_td_cb_text">0.12mm</td>
					</tr>
					<tr class="ui_tb_td_capability">
						<td class="ui_tb_td_cb_text">0.40</td>
						<td class="ui_tb_td_cb_text">0.12mm</td>
					</tr>
					<tr class="ui_tb_td_capability">
						<td class="ui_tb_td_cb_text">0.30</td>
						<td class="ui_tb_td_cb_text">0.10mm</td>
					</tr>
					
					<tr class="ui_tb_td_capability">
						<td class="ui_tb_td_cb_text" rowspan="3">BGA</td>
						<td class="ui_tb_td_cb_text">1.25~1.27</td>
						<td class="ui_tb_td_cb_text">0.15mm</td>
					</tr>
					<tr class="ui_tb_td_capability">
						<td class="ui_tb_td_cb_text">1.00</td>
						<td class="ui_tb_td_cb_text">0.12mm</td>
					</tr>
					<tr class="ui_tb_td_capability">
						<td class="ui_tb_td_cb_text">0.5～0.8</td>
						<td class="ui_tb_td_cb_text">0.12mm</td>
					</tr>
					
					<tr class="ui_tb_td_capability">
						<td class="ui_tb_td_cb_text">PLCC</td>
						<td class="ui_tb_td_cb_text">1.25～1.27</td>
						<td class="ui_tb_td_cb_text">0.15mm</td>
					</tr>
				</tbody>
			</table>
			
			<div class="ui_dimension">
				{{$t('steelMesh.userInformation.text3')}}
			</div>
			
			<div class="ui_customersTitle">
				<div class="ui_ct_title">{{$t('steelMesh.userInformation.text4')}}</div>
				<div class="ui_ct_tl_text" style="padding-top: 20px;">{{$t('steelMesh.userInformation.text5')}}</div>
				<div class="ui_ct_tl_text" style="padding-top: 10px;">{{$t('steelMesh.userInformation.text6')}}</div>
			</div>
			
			<div class="ui_customersContent">
				<div class="ui_cc_list" v-for="(item,index) in $t('steelMesh.userInformation.labelList')" :key="index">
					<div class="ui_cc_ls_icon">√</div>
					<div class="ui_cc_ls_text">{{item}}</div>
				</div>
			</div>
		</div>
		
		<div class="userInformation2" :style="'background-image:' + `url(${img})`" v-if="windowWidth<=1199">
			<div class="ui_title">{{$t('steelMesh.userInformation.title')}}</div>
			<div class="ui_textDiv">
				<div class="ui_td_title">{{$t('steelMesh.userInformation.text1')}}</div>
				<div class="ui_td_text">{{$t('steelMesh.userInformation.text2')}}</div>
			</div>
			<table class="ui_table">
				<tbody class="ui_tb_tbody">
					<tr class="ui_tb_td_gaugeOutfit">
						<th style="width: 20%;"></th>
						<th style="width: 50%;"></th>
						<th style="width: 30%;"></th>
					</tr>
					<tr class="ui_tb_td_capability">
						<td class="ui_tb_td_cb_text">{{$t('steelMesh.userInformation.tableGaugeOutfit.text1')}}</td>
						<td class="ui_tb_td_cb_text">{{$t('steelMesh.userInformation.tableGaugeOutfit.text2')}}</td>
						<td class="ui_tb_td_cb_text">{{$t('steelMesh.userInformation.tableGaugeOutfit.text3')}}</td>
					</tr>
					<tr class="ui_tb_td_capability">
						<td class="ui_tb_td_cb_text" rowspan="2">CHIP</td>
						<td class="ui_tb_td_cb_text">0402</td>
						<td class="ui_tb_td_cb_text">0.12mm</td>
					</tr>
					<tr class="ui_tb_td_capability">
						<td class="ui_tb_td_cb_text">0201</td>
						<td class="ui_tb_td_cb_text">0.10mm</td>
					</tr>
					
					<tr class="ui_tb_td_capability">
						<td class="ui_tb_td_cb_text" rowspan="4">QFP</td>
						<td class="ui_tb_td_cb_text">0.65</td>
						<td class="ui_tb_td_cb_text">0.15mm</td>
					</tr>
					<tr class="ui_tb_td_capability">
						<td class="ui_tb_td_cb_text">0.50</td>
						<td class="ui_tb_td_cb_text">0.12mm</td>
					</tr>
					<tr class="ui_tb_td_capability">
						<td class="ui_tb_td_cb_text">0.40</td>
						<td class="ui_tb_td_cb_text">0.12mm</td>
					</tr>
					<tr class="ui_tb_td_capability">
						<td class="ui_tb_td_cb_text">0.30</td>
						<td class="ui_tb_td_cb_text">0.10mm</td>
					</tr>
					
					<tr class="ui_tb_td_capability">
						<td class="ui_tb_td_cb_text" rowspan="3">BGA</td>
						<td class="ui_tb_td_cb_text">1.25~1.27</td>
						<td class="ui_tb_td_cb_text">0.15mm</td>
					</tr>
					<tr class="ui_tb_td_capability">
						<td class="ui_tb_td_cb_text">1.00</td>
						<td class="ui_tb_td_cb_text">0.12mm</td>
					</tr>
					<tr class="ui_tb_td_capability">
						<td class="ui_tb_td_cb_text">0.5～0.8</td>
						<td class="ui_tb_td_cb_text">0.12mm</td>
					</tr>
					
					<tr class="ui_tb_td_capability">
						<td class="ui_tb_td_cb_text">PLCC</td>
						<td class="ui_tb_td_cb_text">1.25～1.27</td>
						<td class="ui_tb_td_cb_text">0.15mm</td>
					</tr>
				</tbody>
			</table>
			
			<div class="ui_dimension">
				{{$t('steelMesh.userInformation.text3')}}
			</div>
			
			<div class="ui_customersTitle">
				<div class="ui_ct_title">{{$t('steelMesh.userInformation.text4')}}</div>
				<div class="ui_ct_tl_text" style="padding-top: 20px;">{{$t('steelMesh.userInformation.text5')}}</div>
				<div class="ui_ct_tl_text" style="padding-top: 10px;">{{$t('steelMesh.userInformation.text6')}}</div>
			</div>
			
			<div class="ui_customersContent">
				<div class="ui_cc_list" v-for="(item,index) in $t('steelMesh.userInformation.labelList')" :key="index">
					<div class="ui_cc_ls_icon">√</div>
					<div class="ui_cc_ls_text">{{item}}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				list:["GERBER文件","PCB文件","CAD文件","GBR文件","CAM文件","DXF文件或者是PCB实物空板"],
				img:process.env.VUE_APP_OSS_URL + '/manufacture/patch-weld-bg.jpg',
			}
		},
		
		created() {
			
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.userInformation{
			padding: 40px 200px;
			
			.ui_title{
				text-align: center;
				font-size: 32px;
				color: #2a915f;
				text-align: center;
				padding-top: 60px;
			}
			
			.ui_textDiv{
				padding-top: 20px;
				
				.ui_td_title{
					font-size: 24px;
					color: #fff;
				}
				
				.ui_td_text{
					font-size: 14px;
					color: #fff;
					padding-top: 10px;
				}
			}
			
			.ui_table{
				margin-top: 10px;
				width: 100%;
				position: relative;
				z-index: 10;
				border-collapse: collapse;
				margin-top: 20px;
				
				.ui_tb_tbody{
					display: table-row-group;
					vertical-align: middle;
					border-color: inherit;
					color: #fff;
					
					.ui_tb_td_gaugeOutfit{
						display: table-row;
						vertical-align: inherit;
						border-color: inherit;
					}
					
					.ui_tb_td_capability{
						display: table-row;
						vertical-align: inherit;
						border-color: inherit;
						
						.ui_tb_td_cb_text{
							border: 1px solid #606060;
							padding: 10px 0;
							text-align: center;
						}
					}
				}
			}
			
			.ui_dimension{
				color: #2a915f;
				font-size: 14px;
				padding-top: 10px;
			}
			
			.ui_customersTitle{
				padding-top: 20px;
				
				.ui_ct_title{
					font-size: 24px;
					color: #fff;
				}
				
				.ui_ct_tl_text{
					font-size: 14px;
					color: #2a915f;
				}
			}
			
			.ui_customersContent{
				display: flex;
				flex-wrap: wrap;
				width: 100%;
				margin-top: 20px;
				
				.ui_cc_list{
					display: flex;
					align-items: center;
					color: #fff;
					margin-right: 20px;
					
					.ui_cc_ls_icon{
						background: #2a915f;
						padding: 2px 7px;
						border-radius: 40px;
						color: #333333;
					}
					
					.ui_cc_ls_text{
						padding-left: 10px;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.userInformation{
			padding: 40px 200px;
			
			.ui_title{
				text-align: center;
				font-size: 32px;
				color: #2a915f;
				text-align: center;
				padding-top: 60px;
			}
			
			.ui_textDiv{
				padding-top: 20px;
				
				.ui_td_title{
					font-size: 24px;
					color: #fff;
				}
				
				.ui_td_text{
					font-size: 14px;
					color: #fff;
					padding-top: 10px;
				}
			}
			
			.ui_table{
				margin-top: 10px;
				width: 100%;
				position: relative;
				z-index: 10;
				border-collapse: collapse;
				margin-top: 20px;
				
				.ui_tb_tbody{
					display: table-row-group;
					vertical-align: middle;
					border-color: inherit;
					color: #fff;
					
					.ui_tb_td_gaugeOutfit{
						display: table-row;
						vertical-align: inherit;
						border-color: inherit;
					}
					
					.ui_tb_td_capability{
						display: table-row;
						vertical-align: inherit;
						border-color: inherit;
						
						.ui_tb_td_cb_text{
							border: 1px solid #606060;
							padding: 10px 0;
							text-align: center;
						}
					}
				}
			}
			
			.ui_dimension{
				color: #2a915f;
				font-size: 14px;
				padding-top: 10px;
			}
			
			.ui_customersTitle{
				padding-top: 20px;
				
				.ui_ct_title{
					font-size: 24px;
					color: #fff;
				}
				
				.ui_ct_tl_text{
					font-size: 14px;
					color: #2a915f;
				}
			}
			
			.ui_customersContent{
				display: flex;
				flex-wrap: wrap;
				width: 100%;
				margin-top: 20px;
				
				.ui_cc_list{
					display: flex;
					align-items: center;
					color: #fff;
					margin-right: 20px;
					
					.ui_cc_ls_icon{
						background: #2a915f;
						padding: 2px 7px;
						border-radius: 40px;
						color: #333333;
					}
					
					.ui_cc_ls_text{
						padding-left: 10px;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.userInformation{
			padding: 40px 140px;
			
			.ui_title{
				text-align: center;
				font-size: 32px;
				color: #2a915f;
				text-align: center;
				padding-top: 60px;
			}
			
			.ui_textDiv{
				padding-top: 20px;
				
				.ui_td_title{
					font-size: 24px;
					color: #fff;
				}
				
				.ui_td_text{
					font-size: 14px;
					color: #fff;
					padding-top: 10px;
				}
			}
			
			.ui_table{
				margin-top: 10px;
				width: 100%;
				position: relative;
				z-index: 10;
				border-collapse: collapse;
				margin-top: 20px;
				
				.ui_tb_tbody{
					display: table-row-group;
					vertical-align: middle;
					border-color: inherit;
					color: #fff;
					
					.ui_tb_td_gaugeOutfit{
						display: table-row;
						vertical-align: inherit;
						border-color: inherit;
					}
					
					.ui_tb_td_capability{
						display: table-row;
						vertical-align: inherit;
						border-color: inherit;
						
						.ui_tb_td_cb_text{
							border: 1px solid #606060;
							padding: 10px 0;
							text-align: center;
						}
					}
				}
			}
			
			.ui_dimension{
				color: #2a915f;
				font-size: 14px;
				padding-top: 10px;
			}
			
			.ui_customersTitle{
				padding-top: 20px;
				
				.ui_ct_title{
					font-size: 24px;
					color: #fff;
				}
				
				.ui_ct_tl_text{
					font-size: 14px;
					color: #2a915f;
				}
			}
			
			.ui_customersContent{
				display: flex;
				flex-wrap: wrap;
				width: 100%;
				margin-top: 20px;
				
				.ui_cc_list{
					display: flex;
					align-items: center;
					color: #fff;
					margin-right: 20px;
					
					.ui_cc_ls_icon{
						background: #2a915f;
						padding: 2px 7px;
						border-radius: 40px;
						color: #333333;
					}
					
					.ui_cc_ls_text{
						padding-left: 10px;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.userInformation{
			padding: 40px 30px;
			
			.ui_title{
				text-align: center;
				font-size: 32px;
				color: #2a915f;
				text-align: center;
				padding-top: 60px;
			}
			
			.ui_textDiv{
				padding-top: 20px;
				
				.ui_td_title{
					font-size: 24px;
					color: #fff;
				}
				
				.ui_td_text{
					font-size: 14px;
					color: #fff;
					padding-top: 10px;
				}
			}
			
			.ui_table{
				margin-top: 10px;
				width: 100%;
				position: relative;
				z-index: 10;
				border-collapse: collapse;
				margin-top: 20px;
				
				.ui_tb_tbody{
					display: table-row-group;
					vertical-align: middle;
					border-color: inherit;
					color: #fff;
					
					.ui_tb_td_gaugeOutfit{
						display: table-row;
						vertical-align: inherit;
						border-color: inherit;
					}
					
					.ui_tb_td_capability{
						display: table-row;
						vertical-align: inherit;
						border-color: inherit;
						
						.ui_tb_td_cb_text{
							border: 1px solid #606060;
							padding: 10px 0;
							text-align: center;
						}
					}
				}
			}
			
			.ui_dimension{
				color: #2a915f;
				font-size: 14px;
				padding-top: 10px;
			}
			
			.ui_customersTitle{
				padding-top: 20px;
				
				.ui_ct_title{
					font-size: 24px;
					color: #fff;
				}
				
				.ui_ct_tl_text{
					font-size: 14px;
					color: #2a915f;
				}
			}
			
			.ui_customersContent{
				display: flex;
				flex-wrap: wrap;
				width: 100%;
				margin-top: 20px;
				
				.ui_cc_list{
					display: flex;
					align-items: center;
					color: #fff;
					margin-right: 20px;
					
					.ui_cc_ls_icon{
						background: #2a915f;
						padding: 2px 7px;
						border-radius: 40px;
						color: #333333;
					}
					
					.ui_cc_ls_text{
						padding-left: 10px;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.userInformation2{
			padding: 40px 30px;
			
			.ui_title{
				text-align: center;
				font-size: 21px;
				color: #2a915f;
				text-align: center;
			}
			
			.ui_textDiv{
				padding-top: 20px;
				
				.ui_td_title{
					font-size: 24px;
					color: #fff;
				}
				
				.ui_td_text{
					font-size: 14px;
					color: #fff;
					padding-top: 10px;
				}
			}
			
			.ui_table{
				margin-top: 10px;
				width: 100%;
				position: relative;
				z-index: 10;
				border-collapse: collapse;
				margin-top: 20px;
				
				.ui_tb_tbody{
					display: table-row-group;
					vertical-align: middle;
					border-color: inherit;
					color: #fff;
					
					.ui_tb_td_gaugeOutfit{
						display: table-row;
						vertical-align: inherit;
						border-color: inherit;
					}
					
					.ui_tb_td_capability{
						display: table-row;
						vertical-align: inherit;
						border-color: inherit;
						
						.ui_tb_td_cb_text{
							border: 1px solid #606060;
							padding: 10px 0;
							text-align: center;
						}
					}
				}
			}
			
			.ui_dimension{
				color: #2a915f;
				font-size: 14px;
				padding-top: 10px;
			}
			
			.ui_customersTitle{
				padding-top: 20px;
				
				.ui_ct_title{
					font-size: 24px;
					color: #fff;
				}
				
				.ui_ct_tl_text{
					font-size: 14px;
					color: #2a915f;
				}
			}
			
			.ui_customersContent{
				display: flex;
				flex-wrap: wrap;
				width: 100%;
				margin-top: 20px;
				
				.ui_cc_list{
					display: flex;
					align-items: center;
					color: #fff;
					margin-right: 20px;
					margin-bottom: 10px;
					
					.ui_cc_ls_icon{
						background: #2a915f;
						padding: 2px 7px;
						border-radius: 40px;
						color: #333333;
					}
					
					.ui_cc_ls_text{
						padding-left: 10px;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.userInformation2{
			padding: 40px 30px;
			
			.ui_title{
				text-align: center;
				font-size: 21px;
				color: #2a915f;
				text-align: center;
			}
			
			.ui_textDiv{
				padding-top: 20px;
				
				.ui_td_title{
					font-size: 24px;
					color: #fff;
				}
				
				.ui_td_text{
					font-size: 14px;
					color: #fff;
					padding-top: 10px;
				}
			}
			
			.ui_table{
				margin-top: 10px;
				width: 100%;
				position: relative;
				z-index: 10;
				border-collapse: collapse;
				margin-top: 20px;
				
				.ui_tb_tbody{
					display: table-row-group;
					vertical-align: middle;
					border-color: inherit;
					color: #fff;
					
					.ui_tb_td_gaugeOutfit{
						display: table-row;
						vertical-align: inherit;
						border-color: inherit;
					}
					
					.ui_tb_td_capability{
						display: table-row;
						vertical-align: inherit;
						border-color: inherit;
						
						.ui_tb_td_cb_text{
							border: 1px solid #606060;
							padding: 10px 0;
							text-align: center;
						}
					}
				}
			}
			
			.ui_dimension{
				color: #2a915f;
				font-size: 14px;
				padding-top: 10px;
			}
			
			.ui_customersTitle{
				padding-top: 20px;
				
				.ui_ct_title{
					font-size: 24px;
					color: #fff;
				}
				
				.ui_ct_tl_text{
					font-size: 14px;
					color: #2a915f;
				}
			}
			
			.ui_customersContent{
				display: flex;
				flex-wrap: wrap;
				width: 100%;
				margin-top: 20px;
				
				.ui_cc_list{
					display: flex;
					align-items: center;
					color: #fff;
					margin-right: 20px;
					margin-bottom: 10px;
					
					.ui_cc_ls_icon{
						background: #2a915f;
						padding: 2px 7px;
						border-radius: 40px;
						color: #333333;
					}
					
					.ui_cc_ls_text{
						padding-left: 10px;
					}
				}
			}
		}
	}
	
</style>