<template>
	<div>
		<div class="hotSales" v-if="windowWidth>=1200">
			<div class="hs_div">
				<div class="hs_di_left">
					<div class="hs_di_lf_hot" :style="'background-image:' + `url(${require('@/assets/img/hot-flag.png')})`">
						热
						<br/>
						销
						<br/>
						款
					</div>
					<div class="hs_di_lf_contentDiv">
						<div class="hs_di_lf_cd_img">
							<img style="width: 100%;" :src="$t('steelMesh.hotSales.img1')" />
						</div>
						<div class="hs_di_lf_cd_titleDiv">
							<!-- <span class="hs_di_lf_cd_td_price">￥100</span> -->
							<span class="hs_di_lf_cd_td_text">{{$t('steelMesh.hotSales.leftContent.text1')}}</span>
							{{$t('steelMesh.hotSales.leftContent.text2')}}
						</div>
						<div class="hs_di_lf_cd_label">
							<div class="hs_di_lf_cd_lb_name1">{{$t('steelMesh.hotSales.leftContent.label1')}}</div>
							<div class="hs_di_lf_cd_lb_name1">{{$t('steelMesh.hotSales.leftContent.label2')}}</div>
							<div class="hs_di_lf_cd_lb_name1">{{$t('steelMesh.hotSales.leftContent.label3')}}</div>
							<div class="hs_di_lf_cd_lb_name2">{{$t('steelMesh.hotSales.leftContent.label4')}}</div>
						</div>
						<!-- <a class="hs_di_lf_cd_jump">{{$t('steelMesh.hotSales.leftContent.button')}}</a> -->
					</div>
				</div>
				
				<div class="hs_di_right">
					<div class="hs_di_rg_pointDiv">
						<div class="hs_di_rg_pd_point" :style="pointIndex == 0 ? 'background-color: #2a915f;' : ''" @click="pointClick(0)"></div>
						<div class="hs_di_rg_pd_point" :style="pointIndex == 1 ? 'background-color: #2a915f;' : ''" @click="pointClick(1)"></div>
					</div>
					
					<div class="hs_di_rg_pd_contentDiv" v-if="pointIndex==0">
						<div class="hs_di_rg_pd_cd_title">{{$t('steelMesh.hotSales.rightContent1.text')}}</div>
						<div class="hs_di_rg_pd_cd_textList">
							<div class="text">{{$t('steelMesh.hotSales.rightContent1.label1')}}</div>
							<div class="text">{{$t('steelMesh.hotSales.rightContent1.label2')}}</div>
							<div class="text">{{$t('steelMesh.hotSales.rightContent1.label3')}}</div>
							<div class="text">{{$t('steelMesh.hotSales.rightContent1.label4')}}</div>
						</div>
						<div class="hs_di_rg_pd_cd_imgList">
							<div class="hs_di_rg_pd_cd_il_img">
								<img style="width: 100%;" :src="$t('steelMesh.hotSales.rightContent1.img1')" />
							</div>
							<div class="hs_di_rg_pd_cd_il_img">
								<img style="width: 100%;" :src="$t('steelMesh.hotSales.rightContent1.img2')" />
							</div>
							<div class="hs_di_rg_pd_cd_il_img">
								<img style="width: 100%;" :src="$t('steelMesh.hotSales.rightContent1.img3')" />
							</div>
							<div class="hs_di_rg_pd_cd_il_img">
								<img style="width: 100%;" :src="$t('steelMesh.hotSales.rightContent1.img4')" />
							</div>
						</div>
					</div>
					
					<div class="hs_di_rg_pd_contentDiv" v-if="pointIndex==1">
						<div class="hs_di_rg_pd_cd_title">{{$t('steelMesh.hotSales.rightContent2.text')}}</div>
						<div class="hs_di_rg_pd_cd_textList">
							<div class="text">{{$t('steelMesh.hotSales.rightContent2.label1')}}</div>
							<div class="text">{{$t('steelMesh.hotSales.rightContent2.label2')}}</div>
						</div>
						<div class="hs_di_rg_pd_cd_imgList">
							<div class="hs_di_rg_pd_cd_il_img">
								<img style="width: 100%;" :src="$t('steelMesh.hotSales.rightContent2.img1')" />
							</div>
							<div class="hs_di_rg_pd_cd_il_img">
								<img style="width: 100%;" :src="$t('steelMesh.hotSales.rightContent2.img1')" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="hotSales2" v-if="windowWidth<=1199">
			<div class="hs_div">
				<div class="hs_di_left">
					<div class="hs_di_lf_hot" :style="'background-image:' + `url(${require('@/assets/img/hot-flag.png')})`">
						热
						<br/>
						销
						<br/>
						款
					</div>
					<div class="hs_di_lf_contentDiv">
						<div class="hs_di_lf_cd_img">
							<img style="width: 40vw;" :src="$t('steelMesh.hotSales.img1')" />
						</div>
						<div class="hs_di_lf_cd_titleDiv">
							<!-- <span class="hs_di_lf_cd_td_price">￥100</span> -->
							<span class="hs_di_lf_cd_td_text">{{$t('steelMesh.hotSales.leftContent.text1')}}</span>
							{{$t('steelMesh.hotSales.leftContent.text2')}}
						</div>
						<div class="hs_di_lf_cd_label">
							<div class="hs_di_lf_cd_lb_name1">{{$t('steelMesh.hotSales.leftContent.label1')}}</div>
							<div class="hs_di_lf_cd_lb_name1">{{$t('steelMesh.hotSales.leftContent.label2')}}</div>
							<div class="hs_di_lf_cd_lb_name1">{{$t('steelMesh.hotSales.leftContent.label3')}}</div>
							<div class="hs_di_lf_cd_lb_name2">{{$t('steelMesh.hotSales.leftContent.label4')}}</div>
						</div>
						<!-- <a class="hs_di_lf_cd_jump">{{$t('steelMesh.hotSales.leftContent.button')}}</a> -->
					</div>
				</div>
				
				<div class="hs_di_right">
					<div class="hs_di_rg_pointDiv">
						<div class="hs_di_rg_pd_point" :style="pointIndex == 0 ? 'background-color: #2a915f;' : ''" @click="pointClick(0)"></div>
						<div class="hs_di_rg_pd_point" :style="pointIndex == 1 ? 'background-color: #2a915f;' : ''" @click="pointClick(1)"></div>
					</div>
					
					<div class="hs_di_rg_pd_contentDiv" v-if="pointIndex==0">
						<div class="hs_di_rg_pd_cd_title">{{$t('steelMesh.hotSales.rightContent1.text')}}</div>
						<div class="hs_di_rg_pd_cd_textList">
							<div class="text">{{$t('steelMesh.hotSales.rightContent1.label1')}}</div>
							<div class="text">{{$t('steelMesh.hotSales.rightContent1.label2')}}</div>
							<div class="text">{{$t('steelMesh.hotSales.rightContent1.label3')}}</div>
							<div class="text">{{$t('steelMesh.hotSales.rightContent1.label4')}}</div>
						</div>
						<div class="hs_di_rg_pd_cd_imgList">
							<div class="hs_di_rg_pd_cd_il_img">
								<img style="width: 100%;" :src="$t('steelMesh.hotSales.rightContent1.img1')" />
							</div>
							<div class="hs_di_rg_pd_cd_il_img">
								<img style="width: 100%;" :src="$t('steelMesh.hotSales.rightContent1.img2')" />
							</div>
							<div class="hs_di_rg_pd_cd_il_img">
								<img style="width: 100%;" :src="$t('steelMesh.hotSales.rightContent1.img3')" />
							</div>
							<div class="hs_di_rg_pd_cd_il_img">
								<img style="width: 100%;" :src="$t('steelMesh.hotSales.rightContent1.img4')" />
							</div>
						</div>
					</div>
					
					<div class="hs_di_rg_pd_contentDiv" v-if="pointIndex==1">
						<div class="hs_di_rg_pd_cd_title">{{$t('steelMesh.hotSales.rightContent2.text')}}</div>
						<div class="hs_di_rg_pd_cd_textList">
							<div class="text">{{$t('steelMesh.hotSales.rightContent2.label1')}}</div>
							<div class="text">{{$t('steelMesh.hotSales.rightContent2.label2')}}</div>
						</div>
						<div class="hs_di_rg_pd_cd_imgList">
							<div class="hs_di_rg_pd_cd_il_img">
								<img style="width: 100%;" :src="$t('steelMesh.hotSales.rightContent2.img1')" />
							</div>
							<div class="hs_di_rg_pd_cd_il_img">
								<img style="width: 100%;" :src="$t('steelMesh.hotSales.rightContent2.img1')" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				pointIndex:0,
			}
		},
		
		created() {
			
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			//---------------------------指向标点点击事件-------------------------------
			pointClick:function(index){
				this.pointIndex=index;
			}
		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.hotSales{
			padding: 40px 200px;
			
			.hs_div{
				width: 100%;
				background: #fff;
				box-shadow: 0 0 14px -2px rgb(97 97 97 / 20%);
				border: 1px solid #e6e6e6;
				display: flex;
				
				.hs_di_left{
					width: 25%;
					height: inherit;
					border-right: 1px solid #e6e6e6;
					position: relative;
					border-right: 1px solid #e6e6e6;
					
					.hs_di_lf_hot{
						position: absolute;
						top: 0;
						left: 0;
						z-index: 3;
						width: 40px;
						height: 70px;
						padding-top: 5px;
						text-align: center;
						font-size: 14px;
						line-height: 16px;
						color: #ffffff;
						font-weight: bold;
					}
					
					.hs_di_lf_contentDiv{
						width: 100%;
						padding: 2vw;
						
						.hs_di_lf_cd_img{
							text-align: center;
							margin: 40px 0 27px;
						}
						
						.hs_di_lf_cd_titleDiv{
							font-size: 16px;
							text-align: center;
							
							.hs_di_lf_cd_td_price{
								font-size: 18px;
								color: #f53848;
								font-weight: bold;
							}
							
							.hs_di_lf_cd_td_text{
								color: #444444;
								font-weight: bold;
							}
						}
						
						.hs_di_lf_cd_label{
							display: flex;
							flex-wrap: wrap;
							margin-top: 20px;
							
							.hs_di_lf_cd_lb_name1{
								text-align: center;
								border: 1px solid #e6e6e6;
								margin: 0 0 16px 16px;
								padding: 5px;
							}
							
							.hs_di_lf_cd_lb_name2{
								text-align: center;
								border: 1px solid #e6e6e6;
								margin: 0 0 16px 16px;
								padding: 5px;
							}
						}
						
						.hs_di_lf_cd_jump{
							display: block;
							font-size: 16px;
							height: 40px;
							line-height: 40px;
							text-align: center;
							border-radius: 30px;
							color: #ffffff;
							background-color: #2a915f;
							box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
						}
					}
				}
				
				.hs_di_right{
					width: 75%;
					padding: 2vw;
					position: relative;
					
					.hs_di_rg_pointDiv{
						position: absolute;
						top: 40px;
						right: 40px;
						z-index: 6;
						cursor: pointer;
						
						.hs_di_rg_pd_point{
							display: inline-block;
							width: 14px;
							height: 14px;
							background-color: #e6e6e6;
							border-radius: 100%;
							margin-left: 5px;
						}
					}
					
					.hs_di_rg_pd_contentDiv{
						
						.hs_di_rg_pd_cd_title{
							font-size: 24px;
							color: #2a915f;
							font-weight: bold;
						}
						
						.hs_di_rg_pd_cd_textList{
							margin-top: 30px;
							
							.text{
								background: url('../../../../assets/img/marquee.png') no-repeat left center;
								text-indent: 25px;
								font-size: 16px;
								color: #333333;
								margin-bottom: 10px;
							}
						}
						
						.hs_di_rg_pd_cd_imgList{
							margin-top: 25px;
							display: flex;
							width: 100%;
							flex-wrap: wrap;
							
							.hs_di_rg_pd_cd_il_img{
								width: 24%;
								margin-right: 1%;
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.hotSales{
			padding: 40px 200px;
			
			.hs_div{
				width: 100%;
				background: #fff;
				box-shadow: 0 0 14px -2px rgb(97 97 97 / 20%);
				border: 1px solid #e6e6e6;
				display: flex;
				
				.hs_di_left{
					width: 25%;
					height: inherit;
					border-right: 1px solid #e6e6e6;
					position: relative;
					border-right: 1px solid #e6e6e6;
					
					.hs_di_lf_hot{
						position: absolute;
						top: 0;
						left: 0;
						z-index: 3;
						width: 40px;
						height: 70px;
						padding-top: 5px;
						text-align: center;
						font-size: 14px;
						line-height: 16px;
						color: #ffffff;
						font-weight: bold;
					}
					
					.hs_di_lf_contentDiv{
						width: 100%;
						padding: 2vw;
						
						.hs_di_lf_cd_img{
							text-align: center;
							margin: 40px 0 27px;
						}
						
						.hs_di_lf_cd_titleDiv{
							font-size: 16px;
							text-align: center;
							
							.hs_di_lf_cd_td_price{
								font-size: 18px;
								color: #f53848;
								font-weight: bold;
							}
							
							.hs_di_lf_cd_td_text{
								color: #444444;
								font-weight: bold;
							}
						}
						
						.hs_di_lf_cd_label{
							display: flex;
							flex-wrap: wrap;
							margin-top: 20px;
							
							.hs_di_lf_cd_lb_name1{
								text-align: center;
								border: 1px solid #e6e6e6;
								margin: 0 0 16px 16px;
								padding: 5px;
							}
							
							.hs_di_lf_cd_lb_name2{
								text-align: center;
								border: 1px solid #e6e6e6;
								margin: 0 0 16px 16px;
								padding: 5px;
							}
						}
						
						.hs_di_lf_cd_jump{
							display: block;
							font-size: 16px;
							height: 40px;
							line-height: 40px;
							text-align: center;
							border-radius: 30px;
							color: #ffffff;
							background-color: #2a915f;
							box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
						}
					}
				}
				
				.hs_di_right{
					width: 75%;
					padding: 2vw;
					position: relative;
					
					.hs_di_rg_pointDiv{
						position: absolute;
						top: 40px;
						right: 40px;
						z-index: 6;
						cursor: pointer;
						
						.hs_di_rg_pd_point{
							display: inline-block;
							width: 14px;
							height: 14px;
							background-color: #e6e6e6;
							border-radius: 100%;
							margin-left: 5px;
						}
					}
					
					.hs_di_rg_pd_contentDiv{
						
						.hs_di_rg_pd_cd_title{
							font-size: 24px;
							color: #2a915f;
							font-weight: bold;
						}
						
						.hs_di_rg_pd_cd_textList{
							margin-top: 30px;
							
							.text{
								background: url('../../../../assets/img/marquee.png') no-repeat left center;
								text-indent: 25px;
								font-size: 16px;
								color: #333333;
								margin-bottom: 10px;
							}
						}
						
						.hs_di_rg_pd_cd_imgList{
							margin-top: 25px;
							display: flex;
							width: 100%;
							flex-wrap: wrap;
							
							.hs_di_rg_pd_cd_il_img{
								width: 24%;
								margin-right: 1%;
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.hotSales{
			padding: 40px 140px;
			
			.hs_div{
				width: 100%;
				background: #fff;
				box-shadow: 0 0 14px -2px rgb(97 97 97 / 20%);
				border: 1px solid #e6e6e6;
				display: flex;
				
				.hs_di_left{
					width: 25%;
					height: inherit;
					border-right: 1px solid #e6e6e6;
					position: relative;
					border-right: 1px solid #e6e6e6;
					
					.hs_di_lf_hot{
						position: absolute;
						top: 0;
						left: 0;
						z-index: 3;
						width: 40px;
						height: 70px;
						padding-top: 5px;
						text-align: center;
						font-size: 14px;
						line-height: 16px;
						color: #ffffff;
						font-weight: bold;
					}
					
					.hs_di_lf_contentDiv{
						width: 100%;
						padding: 2vw;
						
						.hs_di_lf_cd_img{
							text-align: center;
							margin: 40px 0 27px;
						}
						
						.hs_di_lf_cd_titleDiv{
							font-size: 16px;
							text-align: center;
							
							.hs_di_lf_cd_td_price{
								font-size: 18px;
								color: #f53848;
								font-weight: bold;
							}
							
							.hs_di_lf_cd_td_text{
								color: #444444;
								font-weight: bold;
							}
						}
						
						.hs_di_lf_cd_label{
							display: flex;
							flex-wrap: wrap;
							margin-top: 20px;
							
							.hs_di_lf_cd_lb_name1{
								text-align: center;
								border: 1px solid #e6e6e6;
								margin: 0 0 16px 16px;
								padding: 5px;
							}
							
							.hs_di_lf_cd_lb_name2{
								text-align: center;
								border: 1px solid #e6e6e6;
								margin: 0 0 16px 16px;
								padding: 5px;
							}
						}
						
						.hs_di_lf_cd_jump{
							display: block;
							font-size: 16px;
							height: 40px;
							line-height: 40px;
							text-align: center;
							border-radius: 30px;
							color: #ffffff;
							background-color: #2a915f;
							box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
						}
					}
				}
				
				.hs_di_right{
					width: 75%;
					padding: 2vw;
					position: relative;
					
					.hs_di_rg_pointDiv{
						position: absolute;
						top: 40px;
						right: 40px;
						z-index: 6;
						cursor: pointer;
						
						.hs_di_rg_pd_point{
							display: inline-block;
							width: 14px;
							height: 14px;
							background-color: #e6e6e6;
							border-radius: 100%;
							margin-left: 5px;
						}
					}
					
					.hs_di_rg_pd_contentDiv{
						
						.hs_di_rg_pd_cd_title{
							font-size: 24px;
							color: #2a915f;
							font-weight: bold;
						}
						
						.hs_di_rg_pd_cd_textList{
							margin-top: 30px;
							
							.text{
								background: url('../../../../assets/img/marquee.png') no-repeat left center;
								text-indent: 25px;
								font-size: 16px;
								color: #333333;
								margin-bottom: 10px;
							}
						}
						
						.hs_di_rg_pd_cd_imgList{
							margin-top: 25px;
							display: flex;
							width: 100%;
							flex-wrap: wrap;
							
							.hs_di_rg_pd_cd_il_img{
								width: 24%;
								margin-right: 1%;
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.hotSales{
			padding: 40px 30px;
			
			.hs_div{
				width: 100%;
				background: #fff;
				box-shadow: 0 0 14px -2px rgb(97 97 97 / 20%);
				border: 1px solid #e6e6e6;
				display: flex;
				
				.hs_di_left{
					width: 25%;
					height: inherit;
					border-right: 1px solid #e6e6e6;
					position: relative;
					border-right: 1px solid #e6e6e6;
					
					.hs_di_lf_hot{
						position: absolute;
						top: 0;
						left: 0;
						z-index: 3;
						width: 40px;
						height: 70px;
						padding-top: 5px;
						text-align: center;
						font-size: 14px;
						line-height: 16px;
						color: #ffffff;
						font-weight: bold;
					}
					
					.hs_di_lf_contentDiv{
						width: 100%;
						padding: 2vw;
						
						.hs_di_lf_cd_img{
							text-align: center;
							margin: 40px 0 27px;
						}
						
						.hs_di_lf_cd_titleDiv{
							font-size: 16px;
							text-align: center;
							
							.hs_di_lf_cd_td_price{
								font-size: 18px;
								color: #f53848;
								font-weight: bold;
							}
							
							.hs_di_lf_cd_td_text{
								color: #444444;
								font-weight: bold;
							}
						}
						
						.hs_di_lf_cd_label{
							display: flex;
							flex-wrap: wrap;
							margin-top: 20px;
							
							.hs_di_lf_cd_lb_name1{
								text-align: center;
								border: 1px solid #e6e6e6;
								margin: 0 0 16px 16px;
								padding: 5px;
							}
							
							.hs_di_lf_cd_lb_name2{
								text-align: center;
								border: 1px solid #e6e6e6;
								margin: 0 0 16px 16px;
								padding: 5px;
							}
						}
						
						.hs_di_lf_cd_jump{
							display: block;
							font-size: 16px;
							height: 40px;
							line-height: 40px;
							text-align: center;
							border-radius: 30px;
							color: #ffffff;
							background-color: #2a915f;
							box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
						}
					}
				}
				
				.hs_di_right{
					width: 75%;
					padding: 2vw;
					position: relative;
					
					.hs_di_rg_pointDiv{
						position: absolute;
						top: 40px;
						right: 40px;
						z-index: 6;
						cursor: pointer;
						
						.hs_di_rg_pd_point{
							display: inline-block;
							width: 14px;
							height: 14px;
							background-color: #e6e6e6;
							border-radius: 100%;
							margin-left: 5px;
						}
					}
					
					.hs_di_rg_pd_contentDiv{
						
						.hs_di_rg_pd_cd_title{
							font-size: 24px;
							color: #2a915f;
							font-weight: bold;
						}
						
						.hs_di_rg_pd_cd_textList{
							margin-top: 30px;
							
							.text{
								background: url('../../../../assets/img/marquee.png') no-repeat left center;
								text-indent: 25px;
								font-size: 16px;
								color: #333333;
								margin-bottom: 10px;
							}
						}
						
						.hs_di_rg_pd_cd_imgList{
							margin-top: 25px;
							display: flex;
							width: 100%;
							flex-wrap: wrap;
							
							.hs_di_rg_pd_cd_il_img{
								width: 24%;
								margin-right: 1%;
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.hotSales2{
			padding: 40px 30px;
			
			.hs_div{
				width: 100%;
				background: #fff;
				box-shadow: 0 0 14px -2px rgb(97 97 97 / 20%);
				border: 1px solid #e6e6e6;
				
				.hs_di_left{
					width: 100%;
					height: inherit;
					border-right: 1px solid #e6e6e6;
					position: relative;
					border-right: 1px solid #e6e6e6;
					
					.hs_di_lf_hot{
						position: absolute;
						top: 0;
						left: 0;
						z-index: 3;
						width: 40px;
						height: 70px;
						padding-top: 5px;
						text-align: center;
						font-size: 14px;
						line-height: 16px;
						color: #ffffff;
						font-weight: bold;
					}
					
					.hs_di_lf_contentDiv{
						width: 100%;
						padding: 2vw;
						
						.hs_di_lf_cd_img{
							text-align: center;
							margin: 40px 0 27px;
						}
						
						.hs_di_lf_cd_titleDiv{
							font-size: 16px;
							text-align: center;
							
							.hs_di_lf_cd_td_price{
								font-size: 18px;
								color: #f53848;
								font-weight: bold;
							}
							
							.hs_di_lf_cd_td_text{
								color: #444444;
								font-weight: bold;
							}
						}
						
						.hs_di_lf_cd_label{
							display: flex;
							flex-wrap: wrap;
							margin-top: 20px;
							
							.hs_di_lf_cd_lb_name1{
								text-align: center;
								border: 1px solid #e6e6e6;
								margin: 0 0 16px 16px;
								padding: 5px;
							}
							
							.hs_di_lf_cd_lb_name2{
								text-align: center;
								border: 1px solid #e6e6e6;
								margin: 0 0 16px 16px;
								padding: 5px;
							}
						}
						
						.hs_di_lf_cd_jump{
							display: block;
							font-size: 16px;
							height: 40px;
							line-height: 40px;
							text-align: center;
							border-radius: 30px;
							color: #ffffff;
							background-color: #2a915f;
							box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
						}
					}
				}
				
				.hs_di_right{
					width: 100%;
					padding: 2vw;
					position: relative;
					
					.hs_di_rg_pointDiv{
						position: absolute;
						top: 40px;
						right: 40px;
						z-index: 6;
						cursor: pointer;
						
						.hs_di_rg_pd_point{
							display: inline-block;
							width: 14px;
							height: 14px;
							background-color: #e6e6e6;
							border-radius: 100%;
							margin-left: 5px;
						}
					}
					
					.hs_di_rg_pd_contentDiv{
						
						.hs_di_rg_pd_cd_title{
							font-size: 21px;
							color: #2a915f;
							font-weight: bold;
						}
						
						.hs_di_rg_pd_cd_textList{
							margin-top: 30px;
							
							.text{
								background: url('../../../../assets/img/marquee.png') no-repeat left center;
								text-indent: 25px;
								font-size: 16px;
								color: #333333;
								margin-bottom: 10px;
							}
						}
						
						.hs_di_rg_pd_cd_imgList{
							margin-top: 25px;
							display: flex;
							width: 100%;
							flex-wrap: wrap;
							
							.hs_di_rg_pd_cd_il_img{
								width: 24%;
								margin-right: 1%;
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.hotSales2{
			padding: 40px 30px;
			
			.hs_div{
				width: 100%;
				background: #fff;
				box-shadow: 0 0 14px -2px rgb(97 97 97 / 20%);
				border: 1px solid #e6e6e6;
				
				.hs_di_left{
					width: 100%;
					height: inherit;
					border-right: 1px solid #e6e6e6;
					position: relative;
					border-right: 1px solid #e6e6e6;
					
					.hs_di_lf_hot{
						position: absolute;
						top: 0;
						left: 0;
						z-index: 3;
						width: 40px;
						height: 70px;
						padding-top: 5px;
						text-align: center;
						font-size: 14px;
						line-height: 16px;
						color: #ffffff;
						font-weight: bold;
					}
					
					.hs_di_lf_contentDiv{
						width: 100%;
						padding: 2vw;
						
						.hs_di_lf_cd_img{
							text-align: center;
							margin: 40px 0 27px;
						}
						
						.hs_di_lf_cd_titleDiv{
							font-size: 16px;
							text-align: center;
							
							.hs_di_lf_cd_td_price{
								font-size: 18px;
								color: #f53848;
								font-weight: bold;
							}
							
							.hs_di_lf_cd_td_text{
								color: #444444;
								font-weight: bold;
							}
						}
						
						.hs_di_lf_cd_label{
							display: flex;
							flex-wrap: wrap;
							margin-top: 20px;
							
							.hs_di_lf_cd_lb_name1{
								text-align: center;
								border: 1px solid #e6e6e6;
								margin: 0 0 16px 16px;
								padding: 5px;
							}
							
							.hs_di_lf_cd_lb_name2{
								text-align: center;
								border: 1px solid #e6e6e6;
								margin: 0 0 16px 16px;
								padding: 5px;
							}
						}
						
						.hs_di_lf_cd_jump{
							display: block;
							font-size: 16px;
							height: 40px;
							line-height: 40px;
							text-align: center;
							border-radius: 30px;
							color: #ffffff;
							background-color: #2a915f;
							box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
						}
					}
				}
				
				.hs_di_right{
					width: 100%;
					padding: 2vw;
					position: relative;
					
					.hs_di_rg_pointDiv{
						position: absolute;
						top: 40px;
						right: 40px;
						z-index: 6;
						cursor: pointer;
						
						.hs_di_rg_pd_point{
							display: inline-block;
							width: 14px;
							height: 14px;
							background-color: #e6e6e6;
							border-radius: 100%;
							margin-left: 5px;
						}
					}
					
					.hs_di_rg_pd_contentDiv{
						
						.hs_di_rg_pd_cd_title{
							font-size: 21px;
							color: #2a915f;
							font-weight: bold;
						}
						
						.hs_di_rg_pd_cd_textList{
							margin-top: 30px;
							
							.text{
								background: url('../../../../assets/img/marquee.png') no-repeat left center;
								text-indent: 25px;
								font-size: 16px;
								color: #333333;
								margin-bottom: 10px;
							}
						}
						
						.hs_di_rg_pd_cd_imgList{
							margin-top: 25px;
							display: flex;
							width: 100%;
							flex-wrap: wrap;
							
							.hs_di_rg_pd_cd_il_img{
								width: 24%;
								margin-right: 1%;
							}
						}
					}
				}
			}
		}
	}
	
</style>