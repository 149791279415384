<template>
	<div>
		<div v-if="windowWidth>=1200">
			<div class="equipmentDisplay" :style="'background-image:' + `url(${require('@/assets/img/patch-weld-bg.jpg')})`">
				<div class="ed_title">{{$t('steelMesh.equipmentDisplay.title')}}</div>
			
				<el-carousel height="350px">
					<el-carousel-item class="ed_carouselItem" v-for="(item,index) in imgList" :key="index">
						<div class="ed_ci_img" v-for="(item2,index2) in item.img" :key="index2">
							<img :src="item2" style="width: 100%;" />
						</div>
					</el-carousel-item>
				</el-carousel>
			</div>
			
			<div class="tableContent">
				<table class="tc_table">
					<tbody class="tc_tb_tbody">
						<tr class="tc_tb_gaugeOutfit">
							<th class="tc_tb_go_th">{{$t('steelMesh.equipmentDisplay.text1')}}</th>
							<th class="tc_tb_go_th">{{$t('steelMesh.equipmentDisplay.text2')}}</th>
							<th class="tc_tb_go_th">{{$t('steelMesh.equipmentDisplay.text3')}}</th>
						</tr>
						<tr class="tc_tb_capability" v-for="(item,index) in $t('steelMesh.equipmentDisplay.tableList')" :key="index">
							<th class="tc_tb_ca_th" v-for="(item2,index2) in item.text" :key="index2">{{item2}}</th>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		
		<div v-if="windowWidth<=1199">
			<div class="equipmentDisplay2" :style="'background-image:' + `url(${require('@/assets/img/patch-weld-bg.jpg')})`">
				<div class="ed_title">{{$t('steelMesh.equipmentDisplay.title')}}</div>
			
				<el-carousel height="450px">
					<el-carousel-item class="ed_carouselItem" v-for="(item,index) in imgList2" :key="index">
						<div class="ed_ci_img" v-for="(item2,index2) in item.img" :key="index2">
							<el-image style="width: 100%;" :src="item2" :fit="'fill'"></el-image>
						</div>
					</el-carousel-item>
				</el-carousel>
			</div>
			
			<div class="tableContent2">
				<table class="tc_table">
					<tbody class="tc_tb_tbody">
						<tr class="tc_tb_gaugeOutfit">
							<th class="tc_tb_go_th">{{$t('steelMesh.equipmentDisplay.text1')}}</th>
							<th class="tc_tb_go_th">{{$t('steelMesh.equipmentDisplay.text2')}}</th>
							<th class="tc_tb_go_th">{{$t('steelMesh.equipmentDisplay.text3')}}</th>
						</tr>
						<tr class="tc_tb_capability" v-for="(item,index) in $t('steelMesh.equipmentDisplay.tableList')" :key="index">
							<th class="tc_tb_ca_th" v-for="(item2,index2) in item.text" :key="index2">{{item2}}</th>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				imgList:[{
					img:[process.env.VUE_APP_OSS_URL + '/manufacture/equipment1.png',process.env.VUE_APP_OSS_URL + '/manufacture/equipment2.png',process.env.VUE_APP_OSS_URL + '/manufacture/equipment3.png',process.env.VUE_APP_OSS_URL + '/manufacture/equipment4.png']
				},
				{
					img:[process.env.VUE_APP_OSS_URL + '/manufacture/equipment5.png',process.env.VUE_APP_OSS_URL + '/manufacture/equipment6.png']
				}],
				imgList2:[{
					img:[process.env.VUE_APP_OSS_URL + '/manufacture/equipment1.png']
				},
				{
					img:[process.env.VUE_APP_OSS_URL + '/manufacture/equipment2.png']
				},
				{
					img:[process.env.VUE_APP_OSS_URL + '/manufacture/equipment3.png']
				},
				{
					img:[process.env.VUE_APP_OSS_URL + '/manufacture/equipment4.png']
				},
				{
					img:[process.env.VUE_APP_OSS_URL + '/manufacture/equipment5.png']
				},
				{
					img:[process.env.VUE_APP_OSS_URL + '/manufacture/equipment6.png']
				}],
				
			}
		},

		created() {

		},

		mounted() {

		},

		watch: {

		},

		computed: {

		},

		methods: {

		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.equipmentDisplay{
			padding: 40px 200px;
			
			.ed_title{
				text-align: center;
				font-size: 32px;
				color: #ffffff;
				text-align: center;
				padding-top: 60px;
			}
			
			.ed_carouselItem{
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				
				.ed_ci_img{
					width: 24%;
					margin-right: 1%;
				}
			}
		}
		
		.tableContent{
			padding: 40px 200px;
			background: #fff;
			
			.tc_table{
				width: 100%;
				font-size: 14px;
				
				.tc_tb_tbody{
					display: table-row-group;
					vertical-align: middle;
					border-color: inherit;
					
					.tc_tb_gaugeOutfit{
						background: #2a915f;
						display: table-row;
						vertical-align: inherit;
						border-color: inherit;
						
						.tc_tb_go_th{
							padding: 12px 0 12px 70px;
							background-color: #2a915f;
							color: #ffffff;
							text-align: left;
							font-size: 16px;
							width: 38%;
						}
					}
					
					.tc_tb_capability{
						display: table-row;
						vertical-align: inherit;
						border-color: inherit;
						
						.tc_tb_ca_th{
							padding: 13px 0 13px 70px;
							text-align: left;
							// border-top: 1px solid #e6e6e6;
						}
					}
					
					.tc_tb_capability:nth-of-type(2n+1){
						background-color: #f3f3f3;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.equipmentDisplay{
			padding: 40px 200px;
			
			.ed_title{
				text-align: center;
				font-size: 32px;
				color: #ffffff;
				text-align: center;
				padding-top: 60px;
			}
			
			.ed_carouselItem{
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				
				.ed_ci_img{
					width: 24%;
					margin-right: 1%;
				}
			}
		}
		
		.tableContent{
			padding: 40px 200px;
			background: #fff;
			
			.tc_table{
				width: 100%;
				font-size: 14px;
				
				.tc_tb_tbody{
					display: table-row-group;
					vertical-align: middle;
					border-color: inherit;
					
					.tc_tb_gaugeOutfit{
						background: #2a915f;
						display: table-row;
						vertical-align: inherit;
						border-color: inherit;
						
						.tc_tb_go_th{
							padding: 12px 0 12px 70px;
							background-color: #2a915f;
							color: #ffffff;
							text-align: left;
							font-size: 16px;
							width: 38%;
						}
					}
					
					.tc_tb_capability{
						display: table-row;
						vertical-align: inherit;
						border-color: inherit;
						
						.tc_tb_ca_th{
							padding: 13px 0 13px 70px;
							text-align: left;
							// border-top: 1px solid #e6e6e6;
						}
					}
					
					.tc_tb_capability:nth-of-type(2n+1){
						background-color: #f3f3f3;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.equipmentDisplay{
			padding: 40px 140px;
			
			.ed_title{
				text-align: center;
				font-size: 32px;
				color: #ffffff;
				text-align: center;
				padding-top: 60px;
			}
			
			.ed_carouselItem{
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				
				.ed_ci_img{
					width: 24%;
					margin-right: 1%;
				}
			}
		}
		
		.tableContent{
			padding: 40px 140px;
			background: #fff;
			
			.tc_table{
				width: 100%;
				font-size: 14px;
				
				.tc_tb_tbody{
					display: table-row-group;
					vertical-align: middle;
					border-color: inherit;
					
					.tc_tb_gaugeOutfit{
						background: #2a915f;
						display: table-row;
						vertical-align: inherit;
						border-color: inherit;
						
						.tc_tb_go_th{
							padding: 12px 0 12px 70px;
							background-color: #2a915f;
							color: #ffffff;
							text-align: left;
							font-size: 16px;
							width: 38%;
						}
					}
					
					.tc_tb_capability{
						display: table-row;
						vertical-align: inherit;
						border-color: inherit;
						
						.tc_tb_ca_th{
							padding: 13px 0 13px 70px;
							text-align: left;
							// border-top: 1px solid #e6e6e6;
						}
					}
					
					.tc_tb_capability:nth-of-type(2n+1){
						background-color: #f3f3f3;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.equipmentDisplay{
			padding: 40px 30px;
			
			.ed_title{
				text-align: center;
				font-size: 32px;
				color: #ffffff;
				text-align: center;
				padding-top: 60px;
			}
			
			.ed_carouselItem{
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				
				.ed_ci_img{
					width: 24%;
					margin-right: 1%;
				}
			}
		}
		
		.tableContent{
			padding: 40px 30px;
			background: #fff;
			
			.tc_table{
				width: 100%;
				font-size: 14px;
				
				.tc_tb_tbody{
					display: table-row-group;
					vertical-align: middle;
					border-color: inherit;
					
					.tc_tb_gaugeOutfit{
						background: #2a915f;
						display: table-row;
						vertical-align: inherit;
						border-color: inherit;
						
						.tc_tb_go_th{
							padding: 12px 0 12px 70px;
							background-color: #2a915f;
							color: #ffffff;
							text-align: left;
							font-size: 16px;
							width: 38%;
						}
					}
					
					.tc_tb_capability{
						display: table-row;
						vertical-align: inherit;
						border-color: inherit;
						
						.tc_tb_ca_th{
							padding: 13px 0 13px 70px;
							text-align: left;
							// border-top: 1px solid #e6e6e6;
						}
					}
					
					.tc_tb_capability:nth-of-type(2n+1){
						background-color: #f3f3f3;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.equipmentDisplay2{
			padding: 40px 30px;
			
			.ed_title{
				text-align: center;
				font-size: 21px;
				color: #ffffff;
				text-align: center;
			}
			
			.ed_carouselItem{
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				
				.ed_ci_img{
					width: 50%;
					margin-right: 1%;
				}
			}
		}
		
		.tableContent2{
			padding: 40px 30px;
			background: #fff;
			
			.tc_table{
				width: 100%;
				font-size: 14px;
				
				.tc_tb_tbody{
					display: table-row-group;
					vertical-align: middle;
					border-color: inherit;
					
					.tc_tb_gaugeOutfit{
						background: #2a915f;
						display: table-row;
						vertical-align: inherit;
						border-color: inherit;
						
						.tc_tb_go_th{
							padding: 12px 0 12px 70px;
							background-color: #2a915f;
							color: #ffffff;
							text-align: left;
							font-size: 16px;
							width: 38%;
						}
					}
					
					.tc_tb_capability{
						display: table-row;
						vertical-align: inherit;
						border-color: inherit;
						
						.tc_tb_ca_th{
							padding: 13px 0 13px 70px;
							text-align: left;
							// border-top: 1px solid #e6e6e6;
						}
					}
					
					.tc_tb_capability:nth-of-type(2n+1){
						background-color: #f3f3f3;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.equipmentDisplay2{
			padding: 40px 30px;
			
			.ed_title{
				text-align: center;
				font-size: 21px;
				color: #ffffff;
				text-align: center;
			}
			
			.ed_carouselItem{
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				
				.ed_ci_img{
					width: 50%;
					margin-right: 1%;
				}
			}
		}
		
		.tableContent2{
			padding: 40px 30px;
			background: #fff;
			
			.tc_table{
				width: 100%;
				font-size: 14px;
				
				.tc_tb_tbody{
					display: table-row-group;
					vertical-align: middle;
					border-color: inherit;
					
					.tc_tb_gaugeOutfit{
						background: #2a915f;
						display: table-row;
						vertical-align: inherit;
						border-color: inherit;
						
						.tc_tb_go_th{
							padding: 13px 0 13px 10px;
							background-color: #2a915f;
							color: #ffffff;
							text-align: left;
							font-size: 12px;
							width: 38%;
						}
					}
					
					.tc_tb_capability{
						display: table-row;
						vertical-align: inherit;
						border-color: inherit;
						font-size: 12px;
						
						.tc_tb_ca_th{
							padding: 13px 0 13px 10px;
							text-align: left;
							// border-top: 1px solid #e6e6e6;
						}
					}
					
					.tc_tb_capability:nth-of-type(2n+1){
						background-color: #f3f3f3;
					}
				}
			}
		}
	}
	
</style>
