<template>
	<div style="background: #f7f8f8;">
		<top :windowWidth="windowWidth" :windowHeight="windowHeight"></top>
		<right v-if="windowWidth>=1200"></right>
		<banner :windowWidth="windowWidth" :windowHeight="windowHeight"></banner>
		<hot-sales :windowWidth="windowWidth" :windowHeight="windowHeight"></hot-sales>
		<smt-factory :windowWidth="windowWidth" :windowHeight="windowHeight"></smt-factory>
		<choose-us :windowWidth="windowWidth" :windowHeight="windowHeight"></choose-us>
		<equipment-display :windowWidth="windowWidth" :windowHeight="windowHeight"></equipment-display>
		<user-information :windowWidth="windowWidth" :windowHeight="windowHeight"></user-information>
		<bottom v-if="windowWidth>=1200"></bottom>
	</div>
</template>

<script>
	import top from '@/components/top/index.vue';
	import right from '@/components/right/index.vue';
	import banner from './components/steelMesh/banner.vue';
	import hotSales from './components/steelMesh/hotSales.vue';
	import smtFactory from './components/steelMesh/smtFactory.vue';
	import chooseUs from './components/steelMesh/chooseUs.vue';
	import equipmentDisplay from './components/steelMesh/equipmentDisplay.vue';
	import userInformation from './components/steelMesh/userInformation.vue';
	import bottom from '@/components/bottom/index.vue';
	export default {
		components:{
			top,
			banner,
			hotSales,
			smtFactory,
			chooseUs,
			equipmentDisplay,
			userInformation,
			bottom,
			right
		},
		data() {
			return{
				// 浏览器窗口宽度高度
				windowWidth: 0,
				windowHeight: 0,
			}
		},
		
		created() {
			
		},
		
		mounted() {
			let that = this;
			that.windowWidth = document.body.clientWidth;
			that.windowHeight = document.body.clientHeight;
			window.onresize = () => {
				return (() => {
					this.windowWidth = document.body.clientWidth;
					this.windowHeight = document.body.clientHeight;
				})();
			};
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		},
		
	}
</script>

<style lang="less" scoped>
	
</style>