<template>
	<div>
		<div v-if="windowWidth>=1200">
			<div class="banner" :style="'background-image:' + `url(${require('@/assets/img/banner-bg.jpg')})`">
				<div class="bn_textImg wow animate__bounceInLeft">
					<img style="" :src="$t('steelMesh.banner.img1')" />
				</div>
				<div class="bn_textImg wow animate__bounceInRight" style="padding-top: 20px;">
					<img style="" :src="$t('steelMesh.banner.img2')" />
				</div>
				<div class="bn_textImg" style="padding-top: 20px;">
					<img style="" :src="$t('steelMesh.banner.img3')" />
				</div>
			</div>
			<div class="bannerBottom">
				<div class="bb_listDiv">
					<img style="" :src="$t('steelMesh.banner.img4')" />
					<div class="bb_ld_text">{{$t('steelMesh.banner.text1')}}</div>
				</div>
				<div class="bb_listDiv">
					<img style="" :src="$t('steelMesh.banner.img5')" />
					<div class="bb_ld_text">{{$t('steelMesh.banner.text2')}}</div>
				</div>
				<div class="bb_listDiv">
					<img style="" :src="$t('steelMesh.banner.img6')" />
					<div class="bb_ld_text">{{$t('steelMesh.banner.text3')}}</div>
				</div>
				<div class="bb_listDiv">
					<img style="" :src="$t('steelMesh.banner.img7')" />
					<div class="bb_ld_text">{{$t('steelMesh.banner.text4')}}</div>
				</div>
			</div>
		</div>
		
		<div v-if="windowWidth<=1199">
			<div class="banner2" :style="'background-image:' + `url(${require('@/assets/img/banner-bg.jpg')})`">
				<div class="bn_textImg wow animate__bounceInLeft">
					<el-image style="width: 30vw;" :src="$t('steelMesh.banner.img1')" :fit="'fill'"></el-image>
				</div>
				<div class="bn_textImg wow animate__bounceInRight" style="padding-top: 20px;">
					<el-image style="width: 60vw;" :src="$t('steelMesh.banner.img2')" :fit="'fill'"></el-image>
				</div>
				<div class="bn_textImg" style="padding-top: 20px;">
					<el-image style="width: 30vw;" :src="$t('steelMesh.banner.img3')" :fit="'fill'"></el-image>
				</div>
			</div>
			<div class="bannerBottom2">
				<div class="bb_listDiv">
					<img style="" :src="$t('steelMesh.banner.img4')" />
					<div class="bb_ld_text">{{$t('steelMesh.banner.text1')}}</div>
				</div>
				<div class="bb_listDiv">
					<img style="" :src="$t('steelMesh.banner.img5')" />
					<div class="bb_ld_text">{{$t('steelMesh.banner.text2')}}</div>
				</div>
				<div class="bb_listDiv">
					<img style="" :src="$t('steelMesh.banner.img6')" />
					<div class="bb_ld_text">{{$t('steelMesh.banner.text3')}}</div>
				</div>
				<div class="bb_listDiv">
					<img style="" :src="$t('steelMesh.banner.img7')" />
					<div class="bb_ld_text">{{$t('steelMesh.banner.text4')}}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import WOW from "wowjs";
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return{
				
			}
		},
		
		created() {
			
		},
		
		mounted() {
			let wow = new WOW.WOW({ boxClass: 'wow', animateClass: 'animated', offset:0, mobile: true, live: true }); wow.init();
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.banner{
			padding: 40px 200px;
			
			.bn_textImg{
				display: flex;
				justify-content: center;
			}
		}
		
		.bannerBottom{
			padding: 20px 200px;
			background: #1e1e1e;
			display: flex;
			
			.bb_listDiv{
				width: 25%;
				display: flex;
				align-items: center;
				cursor: pointer;
				transition: all .3s;
				
				.bb_ld_text{
					font-size: 16px;
					color: #d7d7d7;
					margin-left: 15px;
				}
			}
			
			.bb_listDiv:hover{
				transform: translateY(-5px);
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.banner{
			padding: 40px 200px;
			
			.bn_textImg{
				display: flex;
				justify-content: center;
			}
		}
		
		.bannerBottom{
			padding: 20px 200px;
			background: #1e1e1e;
			display: flex;
			
			.bb_listDiv{
				width: 25%;
				display: flex;
				align-items: center;
				cursor: pointer;
				transition: all .3s;
				
				.bb_ld_text{
					font-size: 16px;
					color: #d7d7d7;
					margin-left: 15px;
				}
			}
			
			.bb_listDiv:hover{
				transform: translateY(-5px);
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.banner{
			padding: 40px 140px;
			
			.bn_textImg{
				display: flex;
				justify-content: center;
			}
		}
		
		.bannerBottom{
			padding: 20px 140px;
			background: #1e1e1e;
			display: flex;
			
			.bb_listDiv{
				width: 25%;
				display: flex;
				align-items: center;
				cursor: pointer;
				transition: all .3s;
				
				.bb_ld_text{
					font-size: 16px;
					color: #d7d7d7;
					margin-left: 15px;
				}
			}
			
			.bb_listDiv:hover{
				transform: translateY(-5px);
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.banner{
			padding: 40px 30px;
			
			.bn_textImg{
				display: flex;
				justify-content: center;
			}
		}
		
		.bannerBottom{
			padding: 20px 30px;
			background: #1e1e1e;
			display: flex;
			
			.bb_listDiv{
				width: 25%;
				display: flex;
				align-items: center;
				cursor: pointer;
				transition: all .3s;
				
				.bb_ld_text{
					font-size: 16px;
					color: #d7d7d7;
					margin-left: 15px;
				}
			}
			
			.bb_listDiv:hover{
				transform: translateY(-5px);
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.banner2{
			padding: 100px 30px 40px 30px;
			
			.bn_textImg{
				display: flex;
				justify-content: center;
			}
		}
		
		.bannerBottom2{
			padding: 20px 30px;
			background: #1e1e1e;
			display: flex;
			flex-wrap: wrap;
			
			.bb_listDiv{
				width: 50%;
				margin: 10px 0;
				display: flex;
				align-items: center;
				cursor: pointer;
				transition: all .3s;
				
				.bb_ld_text{
					font-size: 16px;
					color: #d7d7d7;
					margin-left: 15px;
				}
			}
			
			.bb_listDiv:hover{
				transform: translateY(-5px);
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.banner2{
			padding: 100px 30px 40px 30px;
			
			.bn_textImg{
				display: flex;
				justify-content: center;
			}
		}
		
		.bannerBottom2{
			padding: 20px 30px;
			background: #1e1e1e;
			display: flex;
			flex-wrap: wrap;
			
			.bb_listDiv{
				width: 50%;
				margin: 10px 0;
				padding-right: 10px;
				display: flex;
				align-items: center;
				cursor: pointer;
				transition: all .3s;
				
				.bb_ld_text{
					font-size: 14px;
					color: #d7d7d7;
					margin-left: 15px;
				}
			}
			
			.bb_listDiv:hover{
				transform: translateY(-5px);
			}
		}
	}
	
</style>